import { OptionType } from '@app/store/booking-options/types';
import { OrderSegmentDto, OrderSegmentItemDto } from './order-segment.dto';

export class OrderSegment {
    id: string;
    currency: string;
    items: OrderSegmentItem[];
    number: string;
    price: number;
    email: string;

    constructor(data: OrderSegmentDto) {
        this.id = data.id;
        this.currency = data.currency;
        this.items = data.items.map((item) => new OrderSegmentItem(item));
        this.number = data.number;
        this.price = data.price;
        this.email = data.email;
    }
}

export class OrderSegmentItem {
    id: string;
    name: OptionType;
    price: number;
    currency: string;

    constructor(data: OrderSegmentItemDto) {
        this.id = data.id;
        this.name = data.name;
        this.price = data.price;
        this.currency = data.currency;
    }
}
